import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { createUseStyles } from "react-jss"
import DotsIcon from "./dots-icon"
import CloseIcon from "./close-icon"
import loadable from "@loadable/component"
import {
  oidcAddUser,
  oidcRemoveUser,
  oidcAddUserManager,
} from "../state/actions/oidc"
import { Link as RLink } from "@reach/router"

import {
  sizes,
  colorAliases,
  borderRadius,
  boxShadow,
  MOBILE_BREAKPOINT,
} from "../style/design-system"

const OidcClient = loadable.lib(() => import("oidc-client"))

const useStyles = createUseStyles({
  dots: {
    display: `none`,
    marginRight: `-11px`,
  },
  dotsIcon: {},
  hideDotsIcon: {},
  closeIcon: {},
  showCloseIcon: {},
  burger: {
    outline: `none`,
    width: 30,
    height: 28,
    position: `absolute`,
    right: 32,
    bottom: 22,
    transition: `.2s ease-in-out`,
    cursor: `pointer`,
    background: `transparent`,
    display: `block`,
    border: `none`,
    zIndex: 2,
    "& span": {
      display: `block`,
      position: `absolute`,
      height: 5,
      width: `100%`,
      background: colorAliases["darkColor"],
      opacity: 1,
      left: 0,
      transform: `rotate(0deg)`,
      transition: `.2s ease-in-out`,
    },
    "& span:nth-child(1)": {
      top: 0,
    },
    "& span:nth-child(2)": { top: 9 },
    "& span:nth-child(3)": { top: 9 },
    "& span:nth-child(4)": { top: 18 },
  },
  openBurger: {
    "& span:nth-child(1)": {
      top: 9,
      width: 0,
      left: `50%`,
    },
    "& span:nth-child(4)": {
      top: 9,
      width: 0,
      left: `50%`,
    },
    "& span:nth-child(2)": { transform: `rotate(45deg)` },
    "& span:nth-child(3)": { transform: `rotate(-45deg)` },
  },
  burgerMenu: {
    position: `absolute`,
    top: 10,
    right: 18,
    padding: sizes["2xl"],
    background: `white`,
    borderRadius: borderRadius["sm"],
    boxShadow: boxShadow["lg"],
    transition: `opacity .2s ease-in-out, transform .2s ease-in-out`,
    opacity: 0,
    transform: `translate(0, -100%)`,
    listStyle: `none`,
    zIndex: 1,
    "& > li": {
      marginBottom: sizes["sm"],
    },
    "& > li:last-of-type": {
      marginBottom: 0,
    },
    "& > li > a": {
      textDecoration: `none`,
    },
  },
  openBurgerMenu: {
    opacity: 1,
    transform: `translate(0, 0)`,
  },
  overlay: {
    opacity: 0,
    transition: `opacity 300ms ease-out`,
  },
  showOverlay: {
    position: `fixed`,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: colorAliases["overlayColor"],
    opacity: `.3`,
    zIndex: 1,
  },
  loginOrOutButton: {
    background: `transparent`,
    border: `none`,
    color: colorAliases["darkColor"],
    cursor: `pointer`,
    padding: 0,
  },
  loginOrOutButtonExternal: {
    position: `absolute`,
    right: 89,
    bottom: 24,
  },
  [`@media (max-width: ${MOBILE_BREAKPOINT}px)`]: {
    burgerMenu: {
      top: 12,
      right: 12,
    },
    loginOrOutButtonExternal: {
      right: 50,
      bottom: 26,
    },
    burger: {
      display: `none`,
    },
    dots: {
      outline: `none`,
      width: 27,
      height: 27,
      position: `absolute`,
      right: 32,
      bottom: 24,
      transition: `.2s ease-in-out`,
      cursor: `pointer`,
      background: `transparent`,
      display: `block`,
      border: `none`,
      zIndex: 2,
    },
    dotsIcon: {
      position: `absolute`,
      top: 0,
      right: 0,
      opacity: 1,
      transition: `opacity 200ms ease-out`,
    },
    hideDotsIcon: {
      opacity: 0,
    },
    closeIcon: {
      position: `absolute`,
      top: 0,
      right: 0,
      opacity: 0,
      transition: `opacity 200ms ease-out`,
    },
    showCloseIcon: {
      opacity: 1,
    },
  },
})

const Header = ({
  closeSidebar,
  oidcUser,
  oidcUserManager,
  addUser,
  addUserManager,
  removeUser,
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const userEmail = oidcUser ? oidcUser.profile.email : ``

  useEffect(() => {
    if (oidcUserManager) {
      oidcUserManager
        .getUser()
        .then(user => {
          addUser(user)
          return user
        })
        .then(user => {
          if (typeof Intercom === `function` && window.IntercomAppId) {
            return fetch(process.env.INTERCOM_INFO_ENDPOINT, {
              method: `GET`,
              headers: {
                "Content-Type": `application/json`,
                Authorization: `Bearer ${user.access_token}`,
              },
            })
          }
        })
        .then(response => response.json())
        .then(userdata => {
          window.Intercom("boot", {
            ...userdata,
            hide_default_launcher: true,
          })
          window.Intercom(`trackEvent`, `kontohjelp-login`)
        })
        .catch(err => {
          console.error(err)
        })
    } else {
      if (typeof Intercom === `function` && window.IntercomAppId) {
        window.Intercom("boot", {
          app_id: "igto4vfp",
          hide_default_launcher: true,
        })
      }
    }
  }, [userEmail])

  return (
    <div>
      <OidcClient>
        {oidcClient => {
          if (!oidcUserManager && typeof window !== `undefined`) {
            const manager = new oidcClient.UserManager({
              authority: process.env.AUTHORITY,
              client_id: process.env.CLIENT_ID,
              redirect_uri: window.location.origin + process.env.REDIRECT_URI,
              response_type: process.env.RESPONSE_TYPE,
              scope: process.env.SCOPE,
              post_logout_redirect_uri:
                window.location.origin + process.env.POST_LOGOUT_REDIRECT_URI,
              automaticSilentRenew: true,
              silent_redirect_uri:
                window.location.origin + process.env.SILENT_REDIRECT_URI,
            })
            manager.events.addSilentRenewError(e => {
              console.log("silent renew error", e.message)
            })
            manager
              .signinSilent()
              .then(user => {
                addUser(user)
              })
              .catch(e => {
                //will allways get an error when user is logged out, but no one cares
              })

            addUserManager(manager)
          }

          return null
        }}
      </OidcClient>
      {!oidcUser ? (
        <button
          data-testid="header-login-button"
          className={[
            classes.loginOrOutButton,
            classes.loginOrOutButtonExternal,
          ].join(` `)}
          type="button"
          onClick={() => {
            window.sessionStorage.setItem(
              `loginLocation`,
              window.decodeURIComponent(window.location.pathname)
            )
            oidcUserManager.signinRedirect()
          }}
        >
          Logg inn
        </button>
      ) : null}
      <div
        className={
          open
            ? [classes.overlay, classes.showOverlay].join(` `)
            : classes.overlay
        }
        onClick={() => {
          setOpen(false)
        }}
      ></div>
      <ul
        className={
          open
            ? [classes.burgerMenu, classes.openBurgerMenu].join(` `)
            : classes.burgerMenu
        }
      >
        {oidcUser ? (
          <li>
            <a href="https://login.dib.no/kontohjelp/endrepassord">
              Endre Passord
            </a>
          </li>
        ) : null}
        {oidcUser ? (
          <li>
            <button
              className={classes.loginOrOutButton}
              type="button"
              onClick={() => {
                removeUser()
                oidcUserManager.signoutRedirect()
              }}
            >
              Logg ut
            </button>
          </li>
        ) : null}

        {!oidcUser ? (
          <li>
            <button
              className={classes.loginOrOutButton}
              type="button"
              onClick={() => {
                window.sessionStorage.setItem(
                  `loginLocation`,
                  window.decodeURIComponent(window.location.pathname)
                )
                oidcUserManager.signinRedirect()
              }}
            >
              Logg inn
            </button>
          </li>
        ) : null}

        {!oidcUser ? (
          <li>
            <a
              href="https://login.dib.no/kontohjelp/kj%C3%B8p"
              target="_blank"
              rel={`noopener noreferrer`}
            >
              Registrer deg / kjøp
            </a>
          </li>
        ) : null}

        <li>
          <a
            href="https://dib.no/lisens"
            target="_blank"
            rel={`noopener noreferrer`}
          >
            Lisensvilkår
          </a>
        </li>
        <li>
          <a
            href="https://dib.no/personvern"
            target="_blank"
            rel={`noopener noreferrer`}
          >
            Personvernerklæring
          </a>
        </li>

        {oidcUser ? (
          <li>
            <button
              className={classes.loginOrOutButton}
              type="button"
              id="kontohjelp-intercom-launcher-button"
            >
              Kontakt oss
            </button>
          </li>
        ) : null}

        <li>
          <a
            href="https://bompengekalkulator.no/"
            target="_blank"
            rel={`noopener noreferrer`}
          >
            Bompengekalkulator
          </a>
        </li>
        <li>
          <RLink
            to="/valutakalkulator"
            onClick={() => {
              setOpen(false)
              closeSidebar()
            }}
          >
            Valutakalkulator
          </RLink>
        </li>
        <li>
          <RLink
            to="/mva-kalkulator"
            onClick={() => {
              setOpen(false)
              closeSidebar()
            }}
          >
            MVA-Kalkulator
          </RLink>
        </li>
      </ul>

      <button
        type="button"
        className={
          open ? [classes.burger, classes.openBurger].join(` `) : classes.burger
        }
        onClick={() => setOpen(!open)}
      >
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </button>

      <button type="button" className={classes.dots}>
        <DotsIcon
          className={
            open
              ? [classes.dotsIcon, classes.hideDotsIcon].join(` `)
              : classes.dotsIcon
          }
          onClick={() => setOpen(!open)}
          color={colorAliases["darkColor"]}
        ></DotsIcon>
        <CloseIcon
          className={
            open
              ? [classes.closeIcon, classes.showCloseIcon].join(` `)
              : classes.closeIcon
          }
          onClick={() => setOpen(!open)}
          color={colorAliases["darkColor"]}
        ></CloseIcon>
      </button>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    oidcUser: state.oidcUser,
    oidcUserManager: state.oidcUserManager,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addUser: user => dispatch(oidcAddUser(user)),
    addUserManager: userManager => dispatch(oidcAddUserManager(userManager)),
    removeUser: () => dispatch(oidcRemoveUser()),
  }
}
const HeaderLoginAndMenu = connect(mapStateToProps, mapDispatchToProps)(Header)
export default HeaderLoginAndMenu
